import React from 'react';
import { isNullOrUndefined } from '@common/helpers/isNullOrUndefined';
import { useTranslation } from '@common/hooks/useTranslation';
import { Text } from '@components/Text';
import {
  getImmediateStartSummary,
  getRightToWorkSummary,
  getSalarySummary,
  getShiftAvailabilitySummary,
  getWorkExperienceSummary
} from './summary';
import {
  ImmediateStartPhrase,
  JobForBaseSummary,
  RightToWorkDocument
} from './types';

type Props = {
  job: JobForBaseSummary;
  rightToWorkDocument: RightToWorkDocument;
  immediateStartPhrase: ImmediateStartPhrase;
};

export const BaseJobSummary = (props: Props) => {
  const { t } = useTranslation();
  const { job, rightToWorkDocument, immediateStartPhrase } = props;

  const isSalaryVisible = job.salaryShowInJobInfo ?? true;

  const salarySummary = getSalarySummary(job.salary, t);

  return (
    <div className="flex flex-col gap-sm">
      <Text>
        {t('postJobForm.summary.blurb', {
          businessName: job.businessName,
          jobTitle: job.jobTitle,
          location: job.location,
          jobType: t(`postJobForm.summary.jobType.${job.jobType}`).toLowerCase()
        })}
      </Text>

      <div>
        <Text weight="regular">{t('postJobForm.summary.title')}</Text>

        <ul className="list-disc ml-10 font-light">
          <li>
            <Text>{getWorkExperienceSummary(job.workExperience, t)}</Text>
          </li>
          {salarySummary && isSalaryVisible && (
            <li>
              <Text>{salarySummary}</Text>
            </li>
          )}
          <li>
            <Text>{t(`postJobForm.summary.jobType.${job.jobType}`)}</Text>
          </li>
          <li>
            <div>
              <Text>{t('postJobForm.summary.shiftAvailability.title')}</Text>
            </div>
            <ul className="list-disc ml-10">
              {getShiftAvailabilitySummary(job.shiftAvailability, t)}
            </ul>
          </li>
          {!isNullOrUndefined(job.rightToWork) && (
            <li>
              <Text>
                {getRightToWorkSummary(job.rightToWork, rightToWorkDocument, t)}
              </Text>
            </li>
          )}
          {job.immediateStart && (
            <li>
              <Text>{getImmediateStartSummary(immediateStartPhrase, t)}</Text>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
