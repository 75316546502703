import { CountryToCurrencyMap } from '@seek/je-shared-data/lib/types/brand';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { JobItemState } from '@store/entities/jobs';
import { FormData } from '../EditJobStep';

export const toEditJobFormData = (
  jobUpdates: FormData,
  job: JobItemState,
  business?: BusinessWithUsers
): FormData => {
  const salaryShowInJobInfo =
    jobUpdates.salaryShowInJobInfo ?? job?.salaryShowInJobInfo;

  const formData: FormData = {
    jobTitle: jobUpdates.jobTitle ?? {
      jobTitle: job?.title,
      role: job?.jobRole
    },
    workExperience: jobUpdates.workExperience ?? job?.workExperience,
    salary: jobUpdates.salary ?? job?.salary,
    jobType: jobUpdates.jobType ?? job?.jobType,
    shiftAvailability: jobUpdates.shiftAvailability ?? job?.shiftAvailability,
    salaryShowInJobInfo:
      salaryShowInJobInfo === undefined ? true : salaryShowInJobInfo
  };

  if (business) {
    formData.business = {
      id: business.id,
      name: business.name,
      geolocation: business.locations[0],
      businessSize: business.businessSize,
      recruitmentCompanyName: business.recruitmentCompanyName
    };

    if (!formData.salary) {
      const businessCountryCode =
        business.locations[0].countryCode.toLowerCase();
      formData.salary = {
        type: 'exact',
        amount: undefined,
        currency: CountryToCurrencyMap[businessCountryCode],
        rate: SalaryRate.MONTHLY
      };
    }
  }

  return formData;
};
