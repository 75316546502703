import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { DisplaySalaryField } from './DisplaySalaryField';

type DisplaySalaryProps<T extends FieldValues> = UseControllerProps<T> & {
  overrides?: {
    label?: string;
  };
};

export function DisplaySalary<T extends FieldValues>({
  name,
  control,
  overrides
}: DisplaySalaryProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DisplaySalaryField
          onChange={onChange}
          label={overrides?.label}
          value={value}
        />
      )}
    />
  );
}
