import React, { useState } from 'react';
import queryString from 'query-string';
import {
  BasicDetailsForm,
  BasicDetailsFormData
} from '@common/components/Job/BasicJobDetails';
import {
  TitleAndBusinessForm,
  TitleAndBusinessFormData
} from '@common/components/Job/TitleAndBusiness';
import { useLoadBusinesses } from '@common/hooks/business';
import { useCreateJob } from '@common/hooks/job';
import { useUpdateRepostDraft } from '@common/hooks/job/useUpdateDraft';
import { type TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import {
  useGetSourceParams,
  useSearchQueryParam
} from '@common/hooks/useSearchQueryParam';
import { type SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { StepError, StepLoader, StepWrapper } from '../../components';
import { useLoadRecentDraft, useMapDraftToFormData } from './hooks';
import { StepIds, stepIds } from './stepsInfo';
import { toUpdateDraftParams } from './transformers/postJobFormData/toUpdateDraftParams';

export type FormData = BasicDetailsFormData & TitleAndBusinessFormData;

type Props = Pick<
  TopLevelStep<{ currentStepId: StepIds; repostJobId: string }>,
  'onCompleted' | 'onBack' | 'onNext' | 'stepInfo'
>;

export const RepostJob = ({
  onCompleted,
  onBack,
  onNext,
  stepInfo: { repostJobId, currentStepId }
}: Props) => {
  useRedirectUnauthenticatedUser();

  const sourceParams = useGetSourceParams();
  const { getQueryParam } = useSearchQueryParam();
  const forceNewDraft = Boolean(getQueryParam('new'));

  const { createJobAsync, error: createJobError } = useCreateJob();
  const { data: savedDraft, isLoading: draftLoading } = useLoadRecentDraft(
    repostJobId,
    { isNew: forceNewDraft }
  );
  const {
    updateDraftAsync,
    error: updateDraftError,
    isSuccess: updateDraftSuccess
  } = useUpdateRepostDraft(repostJobId, forceNewDraft);

  const { businesses, isLoading: businessesLoading } = useLoadBusinesses();
  const draftJob = useMapDraftToFormData(savedDraft, businesses);
  const [businessCountryCode, setBusinessCountryCode] = useState<
    SupportedCountryCode | undefined
  >(draftJob.business?.geolocation.countryCode as SupportedCountryCode);

  const isLoading = businessesLoading || draftLoading;
  const error = createJobError || updateDraftError;

  const saveJob = async () =>
    await createJobAsync({
      repostFromJobId: repostJobId,
      sourceParams: queryString.stringify(sourceParams)
    });

  const saveDraft = async (data: FormData) =>
    await updateDraftAsync({
      repostJobId,
      draft: toUpdateDraftParams(data)
    });

  const onSubmitTitleAndBusiness = async (data: TitleAndBusinessFormData) => {
    const countryCode = data.business?.geolocation.countryCode;

    setBusinessCountryCode(countryCode as SupportedCountryCode);
    await saveDraft(data);
    onNext({ ...data, jobCountryCode: businessCountryCode });
  };

  const onSubmitBasicDetails = async (data: BasicDetailsFormData) => {
    await saveDraft(data);

    if (updateDraftSuccess) {
      const { id: jobId } = await saveJob();

      onCompleted({ ...data, jobId, jobCountryCode: businessCountryCode });
    }
  };

  if (isLoading) return <StepLoader />;

  return (
    <StepWrapper>
      {currentStepId === stepIds.title && (
        <TitleAndBusinessForm
          defaultValues={{
            jobTitle: draftJob.jobTitle,
            business: draftJob.business
          }}
          onSubmit={onSubmitTitleAndBusiness}
          onBack={onBack}
          businessOptions={{
            isBusinessEditable: true,
            redirectCreateBusinessTo: 'RepostJob'
          }}
        />
      )}
      {currentStepId === stepIds.basicDetails && (
        <BasicDetailsForm
          defaultValues={{
            workExperience: draftJob.workExperience,
            salary: draftJob.salary,
            jobType: draftJob.jobType,
            shiftAvailability: draftJob.shiftAvailability,
            jobTitle: draftJob.jobTitle,
            salaryShowInJobInfo: draftJob.salaryShowInJobInfo
          }}
          onSubmit={onSubmitBasicDetails}
          onBack={onBack}
        />
      )}
      {error && <StepError error={error} />}
    </StepWrapper>
  );
};
