import { CurrencySymbols } from '@seek/je-shared-data/lib/types/brand';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';
import { ExactSalary, RangeSalary } from '@seek/je-shared-data/lib/types/jobs';

export type ExactSalaryWithOptionalAmount = ExactSalary & {
  amount?: ExactSalary['amount'];
};
export type RangeSalaryWithOptionalAmount = RangeSalary & {
  amount?: RangeSalary['amount'];
};

export function isExactSalaryInput(
  salary: SalaryWithOptionalAmount
): salary is ExactSalaryWithOptionalAmount {
  return salary.type === 'exact';
}

export function isRangeSalaryInput(
  salary: SalaryWithOptionalAmount
): salary is RangeSalaryWithOptionalAmount {
  return salary.type === 'range';
}

export type SalaryWithOptionalAmount = Omit<Salary, 'amount'> & {
  amount?: Partial<RangeSalary['amount']> | ExactSalary['amount'];
};

export type useExpectedSalaryProps = {
  value: SalaryWithOptionalAmount;
  onChange: (value: SalaryWithOptionalAmount) => void;
};

export const useExpectedSalary = ({
  value,
  onChange
}: useExpectedSalaryProps) => {
  const onSalaryByChange = (type: Salary['type']) => {
    onChange({ ...value, type, amount: undefined });
  };

  const onExactAmountChange = (amount?: ExactSalary['amount']) => {
    onChange({ ...value, amount });
  };

  const onRangeAmountChange = (amount?: Partial<RangeSalary['amount']>) => {
    onChange({ ...value, amount });
  };

  const onRateChange = (rate: Salary['rate']) => {
    onChange({ ...value, rate });
  };

  const onCurrencyChange = (currency: Salary['currency']) => {
    onChange({ ...value, currency });
  };

  const currencySymbol = CurrencySymbols[value.currency];

  return {
    onSalaryByChange,
    onExactAmountChange,
    onRangeAmountChange,
    onRateChange,
    onCurrencyChange,
    currencySymbol
  };
};
