import React from 'react';
import { LabelPosition } from './ToggleSwitch';

type RenderLabelProps = {
  label?: string;
  labelPosition?: LabelPosition;
  id?: string;
  disabled?: boolean;
};

/**
 * Renders a label for the toggle switch.
 *
 * @param {string} label - The text to display as the label.
 * @param {LabelPosition} labelPosition - The position of the label (left or right).
 * @param {string} id - The ID of the toggle switch input element.
 * @param {boolean} disabled - Whether the toggle switch is disabled.
 * @returns {JSX.Element|null} The rendered label element or null if no label is provided.
 */
export const ToggleSwitchLabel = ({
  label = '',
  labelPosition = 'left',
  id = 'toggle-switch',
  disabled = false
}: RenderLabelProps) => {
  if (!label) return null;

  return (
    <label
      htmlFor={id}
      className={`
        text-sm
        ${
          disabled
            ? 'text-gray-400 cursor-not-allowed'
            : 'text-gray-700 cursor-pointer'
        }
        ${labelPosition === 'left' ? 'mr-4' : 'ml-4'}
      `}
    >
      {label}
    </label>
  );
};
