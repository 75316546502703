import React from 'react';
import { ResumeWithTickIcon } from '@components/Icon';
import { ToggleSwitch } from '@components/ToggleSwitch';

export type Props = {
  value?: boolean;
  label?: string;
  onChange: (value: boolean) => void;
};

export const DisplaySalaryField = ({
  value = false,
  label = '',
  onChange
}: Props) => {
  return (
    <div className="rounded-sm border-2 border-solid border-gray-100 p-sm">
      <div className="flex flex-row gap-[8px] px-2 py-4">
        <ResumeWithTickIcon tone="success" variant="outline" size="large" />
        <div className="flex-grow text-gray-900 font-light text-sm">
          {label}
        </div>
        <ToggleSwitch
          checked={value}
          onChange={onChange}
          id="job-form-showSalary"
        />
      </div>
    </div>
  );
};
