import React from 'react';
import { ToggleSwitchLabel } from './ToggleSwitchLabel';

export type LabelPosition = 'left' | 'right';
export type ToggleSwitchProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  id?: string;
  label?: string;
  labelPosition?: LabelPosition;
};

/**
 * ToggleSwitch component for rendering a toggle switch with label.
 *
 * @param {boolean} checked - Indicates whether the toggle switch is checked.
 * @param {boolean} disabled - Indicates whether the toggle switch is disabled.
 * @param {function} onChange - Callback function to handle toggle switch change.
 * @param {string} id - The ID of the toggle switch input element.
 * @param {string} label - The text to display as the label.
 * @param {LabelPosition} labelPosition - The position of the label (left or right).
 */
export const ToggleSwitch = ({
  checked = false,
  disabled = false,
  onChange,
  id = 'toggle-switch',
  label = '',
  labelPosition = 'left'
}: ToggleSwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange(e.target.checked);
    }
  };
  const handleLabelClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <div className="inline-flex items-center">
      {labelPosition === 'left' && (
        <ToggleSwitchLabel
          label={label}
          labelPosition={labelPosition}
          id={`${id}-label`}
          disabled={disabled}
        />
      )}

      <div className="relative inline-block w-14 h-8">
        <input
          id={id}
          type="checkbox"
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
          className={`
            peer
            appearance-none
            w-14
            h-8
            rounded-full
            transition-colors
            duration-300
            ${checked ? 'bg-green-700' : 'bg-slate-100'}
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
          `}
        />
        <label
          htmlFor={id}
          className={`
            absolute
            top-0
            left-0
            w-8
            h-8
            bg-white
            rounded-full
            border
            shadow-sm
            transition-transform
            duration-300
            ${checked ? 'translate-x-6 border-green-700' : 'border-slate-300'}
            ${disabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer'}
          `}
          onClick={handleLabelClick}
        ></label>
      </div>

      {labelPosition === 'right' && (
        <ToggleSwitchLabel
          label={label}
          labelPosition={labelPosition}
          id={`${id}-label`}
          disabled={disabled}
        />
      )}
    </div>
  );
};
