import { DraftJob } from '@common/hooks/job/useFindRecentDraft';
import { CountryToCurrencyMap } from '@seek/je-shared-data/lib/types/brand';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { FormData } from '../../PostJob';

export const toPostJobFormData = (
  draft: DraftJob,
  business?: BusinessWithUsers
): FormData => {
  const formData: FormData = {
    jobTitle: { jobTitle: draft?.title, role: draft?.jobRole },
    workExperience: draft?.workExperience,
    salary: draft?.salary,
    salaryShowInJobInfo:
      draft?.salaryShowInJobInfo === undefined
        ? true
        : draft?.salaryShowInJobInfo,
    jobType: draft?.jobType,
    shiftAvailability: draft?.shiftAvailability
  };

  if (business) {
    formData.business = {
      id: business.id,
      name: business.name,
      geolocation: business.locations[0],
      businessSize: business.businessSize,
      recruitmentCompanyName: business.recruitmentCompanyName
    };

    if (!formData.salary) {
      const businessCountryCode =
        business.locations[0].countryCode.toLowerCase();
      formData.salary = {
        type: 'exact',
        amount: undefined,
        currency: CountryToCurrencyMap[businessCountryCode],
        rate: SalaryRate.MONTHLY
      };
    }
  }

  return formData;
};
